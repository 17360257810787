import Amplitude from 'amplitudejs'

const SELECTOR = '[data-audio-src]:not([data-amplitudified])'

export const amplitudify = () => {
  const initialized = Amplitude.getSongs().length !== 0
  getElements().forEach(amplitudifyElement)
  const { playlists } = Amplitude.getConfig()
  // do not initialize when there is no audio players!
  if (Object.keys(playlists).length === 0) return

  // do not initialize more than once!
  if (initialized) {
    Amplitude.bindNewElements()
  } else {
    Amplitude.init({
      // debug: true,
      songs: [{ url: '' }], // can't be empty, just provide dummy song
      playlists, // can't be omitted when initializing
    })
  }
}

export const watchNewAudioPlayers = () => {
  const target = document.documentElement || document.body
  const config = { childList: true, subtree: true }
  const callback = (_mutations, observer) => {
    if (document.querySelector(SELECTOR) === null) return

    observer.disconnect()
    amplitudify()
    observer.observe(target, config)
  }
  new MutationObserver(callback).observe(target, config)
}

const getElements = () => document.querySelectorAll(SELECTOR)

const amplitudifyElement = (element) => {
  const playlistId = generatePlaylistId()
  addAudioPlayer(element, playlistId)
  Amplitude.addPlaylist(playlistId, {}, [{ url: element.dataset.audioSrc }])
}

const generatePlaylistId = () =>
  Object.keys(Amplitude.getConfig().playlists).length

const addAudioPlayer = (element, playlistId) => {
  element.dataset.amplitudified = ''
  element.replaceChildren(createAudioPlayer())
  element.querySelectorAll('[data-amplitude-playlist]').forEach((child) => {
    child.dataset.amplitudePlaylist = playlistId
  })
}

const createAudioPlayer = () => {
  const template = document.getElementById('audio-player-template')
  if (template === null) return null

  const playerSource = (template.content || template).firstElementChild
  return playerSource.cloneNode(true)
}
