// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@popperjs/core"
import "jquery"
import "bootstrap"
import { amplitudify, watchNewAudioPlayers } from '../shared/amplitudify'
import { activate as activateDisableSubmitOnInvalid } from "../shared/disable-submit-on-invalid"
import '../shared/hamburger-menu'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('turbolinks:load', amplitudify)
watchNewAudioPlayers()

$(document).ready(function(){
  $('.toast').toast('show');
});

activateDisableSubmitOnInvalid();

require('trix')
require('@rails/actiontext')
