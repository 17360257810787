const formSelector = '[data-disable-submit-on-invalid="true"]';

const triggersSelector = [
  `${formSelector} :input[required]`,
  `${formSelector} :input[pattern]`,
].join(', ');

const updateSubmitState = function() {
  const submit = $(this);
  const [form] = submit.closest('form');
  const invalid = form.querySelector(':invalid') !== null;
  console.log(1);
  submit.prop('disabled', invalid);
};

export const activate = () => {
  $(document).on('change keyup', triggersSelector, function() {
    $(this).closest('form').find(':submit').each(updateSubmitState);
  });

  $(() => {
    $(`${formSelector} :submit`).each(updateSubmitState);
  });
};
